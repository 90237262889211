<template>
  <div class="map__wrapper">
    <div class="map__inner"></div>
    <div class="map__button round-button" @click="showMapParams">Enter Map Mode</div>
  </div>
</template>

<script>
export default {
  name: 'Map',
  methods: {
    showMapParams() {
      this.$root.$emit('ShowSidebar', {
        sidebarName: 'MapParams',
        // sidebarStyles: 'left: 0px',
        // animationName: 'sidebarLeft',
        sidebarPosition: 'left'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
